import * as yup from 'yup';

export const schema = yup.object().shape({
  busca_parceiro: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  cod_loja: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  cod_cc: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  cod_categoria: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  cod_finalizadora: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  dta_emissao: yup.string().required(),
  dta_entrada: yup.string().required(),
  val_parcela: yup.string().required(),
  num_docto: yup.string().required(),
  dta_vencimento: yup.string().required(),
  dta_quitada: yup.string().when('flg_quitado', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  flg_quitado: yup.boolean().notRequired(),
  des_observacao: yup.string().notRequired(),
  flg_dta_quitada: yup.boolean().notRequired(),
});
